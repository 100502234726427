import React from 'react';
import { Container } from '../styles';
import CardFinish from '../../components/CardFinish';
import { SubtitleFinish, SubtitleFinishBold } from '../../styles/typography';
import { useTranslation } from 'react-i18next';
import { Logo, ImageDiv, Row, Anchor } from './styles';

const Finish: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <ImageDiv>
        <Logo src="/images/logo.png" />
      </ImageDiv>
      <SubtitleFinish>
        {t('finish-title1')}
        <SubtitleFinishBold>{t('finish-title2')}</SubtitleFinishBold>
        {t('finish-title3')}
      </SubtitleFinish>
      <SubtitleFinish>We make it happen.</SubtitleFinish>
      <Row>
        <Anchor
          href="https://mindconsulting.com.br/wp-content/uploads/2024/03/PORTFOLIO-Mind-Consulting-2024.pdf"
          target="_blank"
        >
          <CardFinish imgName={'conferirPortifolio.webp'} />
        </Anchor>
        <Anchor
          href="https://api.whatsapp.com/send?phone=5511997953909&text=Olá%2C%20tudo%20bem%3F%20Estava%20no%20site%20da%20Mind%20Consulting%20e%20gostaria%20de%20desenvolver%20o%20meu%20projeto%20com%20vocês!&source=&data="
          target="_blank"
        >
          <CardFinish imgName={'falarComEspecialista.webp'} />
        </Anchor>
        <Anchor href="https://beacons.ai/mind_consulting" target="_blank">
          <CardFinish imgName={'verLancamentos.png'} />
        </Anchor>
      </Row>
      <div style={{ minHeight: '40px' }}></div>
    </Container>
  );
};

export default Finish;

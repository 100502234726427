import React, { ChangeEvent, useState } from 'react';
import IconTextInput from '../../components/IconTextInput';
import IconTextEmail from '../../components/IconTextEmail';
import MaskedIconTextInput from '../../components/IconTextInput/masked';
import { TitleService, TextCircle } from '../../styles/typography';
import { Container, Row } from '../styles';
import { FaPhone, FaUser, FaEnvelopeOpen } from 'react-icons/fa';
import TextArea from '../../components/TextArea';
import { Button } from '../../components/Button/styles';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { DevBudgetState, ResponseI } from '../../redux/types/devBudgetTypes';
import { toast } from 'react-toastify';
import api from '../../services/api';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import ToggleButtons from '../../components/ToggleButtons';
import 'react-phone-input-2/lib/bootstrap.css';

const Budget: React.FC = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const devBudget = useSelector<DevBudgetState, DevBudgetState['devBudget']>(
    state => state.devBudget,
  );

  type ITarget = {
    target: {
      name: string;
      value: string;
    };
  };

  const handleChange = (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>
      | ITarget,
    isRadio?: boolean,
  ) => {
    const { name, value } = e.target;

    if (isRadio) {
      setSelected(value);
    }
    dispatch({ type: 'ADD_DEV_BUDGET', payload: { [name]: value } });
  };

  const handleSubmit = async () => {
    const { description, name, phone, size } = devBudget;
    // const { gtagReportConversion } = window as any;
    console.log(devBudget);
    if (description && name && phone && size) {
      // gtagReportConversion();
      // ReactGA.event({
      //   category: 'Conversion',
      //   action: 'Conversion',
      // });
      setDisabled(true);
      await api
        .post('/budget/dev', devBudget)
        .then(({ data }: ResponseI): void => {
          toast.success(data.message);
          history.push('nos-conheca');
        })
        .catch(({ response }) => {
          toast.error(response.data.message);
          setDisabled(false);
        });
    } else {
      toast.error('Preencha todos os campos para continuar!');
    }
  };

  return (
    <Container>
      {/* <Title>
        {t('budget-title')} <span>{t('budget-title2')} </span>
        {t('budget-title3')}
      </Title>
      <TextCircle>{t('budget-subtitle')}</TextCircle>
      <Row style={{ marginBottom: 10 }}>
        <div
          className={'radio'}
          style={
            selected === 'De R$ 50.000 até R$ 80.000'
              ? { backgroundColor: '#dc1e45' }
              : {}
          }
        >
          <input
            type="radio"
            name="budget"
            value="De R$ 50.000 até R$ 80.000"
            id="radio1"
            readOnly
            onChange={e => handleChange(e, true)}
          />
          <label htmlFor="radio1"> {t('budget-valor1')} </label>
        </div>
        <div
          className={'radio'}
          style={
            selected === 'De R$ 80.000 até R$ 110.000'
              ? { backgroundColor: '#dc1e45' }
              : {}
          }
        >
          <input
            type="radio"
            name="budget"
            value="De R$ 80.000 até R$ 110.000"
            id="radio2"
            readOnly
            onChange={e => handleChange(e, true)}
          />{' '}
          <label htmlFor="radio2">{t('budget-valor2')}</label>
        </div>
        <div
          className={'radio'}
          style={
            selected === 'De R$ 110.000 até R$ 150.000'
              ? { backgroundColor: '#dc1e45' }
              : {}
          }
        >
          <input
            type="radio"
            name="budget"
            value="De R$ 110.000 até R$ 150.000"
            id="radio3"
            readOnly
            onChange={e => handleChange(e, true)}
          />{' '}
          <label htmlFor="radio3">{t('budget-valor3')}</label>
        </div>
        <div
          className={'radio'}
          style={
            selected === 'Mais de R$ 150.000'
              ? { backgroundColor: '#dc1e45' }
              : {}
          }
        >
          <input
            type="radio"
            name="budget"
            readOnly
            id="radio4"
            onChange={e => handleChange(e, true)}
            value="Mais de R$ 150.000"
          />{' '}
          <label htmlFor="radio4">{t('budget-valor4')}</label>
        </div>
      </Row> */}
      <TitleService style={{ marginTop: 80 }}>
        {t('budget-title4')} <span>{t('budget-title5')} </span>
        {t('budget-title6')}
      </TitleService>
      <TextCircle>
        {t('budget-text')}
        <br /> {t('budget-text2')}
      </TextCircle>
      <Row>
        <IconTextInput
          icon={FaUser}
          onChange={e => handleChange(e)}
          name="name"
          placeholder={t('budget-nome')}
        />
        <MaskedIconTextInput
          icon={FaPhone}
          placeholder={t('budget-telefone')}
          onChange={e => handleChange(e)}
          name="phone"
        />
      </Row>
      <Row>
        <IconTextEmail
          icon={FaEnvelopeOpen}
          onChange={e => handleChange(e)}
          name="email"
          placeholder={t('budget-email')}
        />
      </Row>
      <TextArea
        placeholder={t('budget-ideia')}
        onChange={e => handleChange(e)}
        name="description"
      />
      <TextCircle>
        {t('budget-text3')} <br />
        {t('budget-text4')}
      </TextCircle>
      <TextArea
        placeholder={t('budget-textarea')}
        onChange={e => handleChange(e)}
        name="timming"
      />
      <TextCircle>{t('company-size')}</TextCircle>
      <ToggleButtons
        onChange={(value: string) =>
          handleChange({ target: { name: 'size', value } })
        }
      />
      {disabled ? (
        <div style={{ margin: 40 }}>
          <Loader
            type="TailSpin"
            width="50"
            height="50"
            color="var(--secondary-300)"
          />
        </div>
      ) : (
        <Button
          style={{ marginTop: 40 }}
          disabled={disabled}
          onClick={handleSubmit}
        >
          {t('budget-button')}
        </Button>
      )}
    </Container>
  );
};

export default Budget;

import styled from 'styled-components';

export const Container = styled.div`
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  @media screen and (max-width: 500px) {
    padding: 10px 10px;
    min-height: 80vh;
  }
  label.custom-select {
    position: relative;
    display: inline-block;
    margin-top: 10px;
  }
  .custom-select select {
    display: inline-block;
    padding: 4px 10px 3px 5px;
    margin: 0;
    font-family: var(--poppins);
    font-size: 15px;
    outline: none; /* remove focus ring from Webkit */
    line-height: 1.2;
    background: #000;
    color: white;
    border: 1px solid #fff;
  }
  .custom-select::after {
    content: '▼';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 60%;
    line-height: 30px;
    padding: 0 7px;
    background: #000;
    color: white;
    pointer-events: none;
    border: 1px solid #fff;
  }
  .no-pointer-events .custom-select:after {
    content: none;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  .radio {
    color: var(--white);
    margin: 20px;
    height: 120px;
    width: 270px;
    border-radius: 50px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.3);
    -moz-box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.3);
    box-shadow: 0px 10px 10px 0px rgba(255, 255, 255, 0.3);
    &:hover {
      -webkit-box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.5);
      -moz-box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.5);
      box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.5);
      transform: scale(1.1);
    }
  }

  .radio input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  .radio label {
    cursor: pointer;
    display: block;
    padding: 50px 0px 50px 0px;
    text-align: center;
    position: relative;
    border-radius: 50px;
    position: relative;
  }

  @media screen and (max-width: 500px) {
    gap: 50px;
  }
`;

export const Anchor = styled.a`
  text-align: center;
  margin: unset;
  padding: unset;
  text-decoration: unset;
  width: min(80%, 300px);
`;

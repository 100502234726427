import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 2.3rem;
  font-weight: bold;
  font-family: var(--poppins);
  margin-top: 80px;
  color: var(--white);
  text-align: center;
  span {
    color: var(--secondary);
  }
  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;

export const TitleService = styled.h1`
  font-size: 2.3rem;
  font-weight: bold;
  font-family: var(--poppins);
  margin-top: 5px;
  padding-bottom: 15px;
  color: var(--white);
  text-align: center;
  span {
    color: var(--secondary);
  }
  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;

export const Subtitle = styled.button`
  background: none;
  height: 60px;
  width: auto;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 2rem;
  font-family: var(--poppins);
  color: var(--white);
  text-align: center;
  line-height: 1.1;
  span {
    font-size: 1.4rem;
    color: var(--secondary);
  }
  @media screen and (max-width: 500px) {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 1rem;
    text-align: center;
    width: 350px;
    height: fit-content;
    margin-top: 10px;
    margin-left: 0px;
    padding: 0;
  }
`;

export const Text = styled.h4`
  font-size: 1rem;
  font-family: var(--poppins);
  margin-top: 80px;
  color: var(--white);
  text-align: center;
  font-weight: normal;
  span {
    color: var(--secondary);
  }
`;

export const TextCircle = styled.h4`
  font-size: 1rem;
  font-family: var(--poppins);
  color: var(--white);
  text-align: center;
  font-weight: normal;
  margin-bottom: 20px;
  padding: 0;
  span {
    color: var(--secondary);
  }
  @media screen and (max-width: 500px) {
    font-size: 0.75rem;
    text-align: left;
    margin-top: 5px;
    padding: 5px;
    margin-left: 10px;
    max-width: 85%;
  }
`;

export const CardAnchor = styled.div`
  font-size: 1.2rem;
  font-family: var(--poppins);
  color: var(--white);
  position: absolute;
  bottom: 25px;
  font-weight: bold;
  width: calc(100% - 20px);
  text-align: center;
  span {
    color: var(--secondary);
  }
`;

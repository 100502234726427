import styled from 'styled-components';

export const Button = styled.button`
  /* cursor: pointer;
  text-align: center;
  padding: 18px 35px;

  color: #fff;

  background: #822929;
  border: unset;
  outline: unset;
  border-radius: 3px;

  margin: 10px;

  transition: all ease-in-out 0.2s;

  &:hover {
    background: #e42333;
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  } */

  font-size: 1.2rem;
  font-family: var(--poppins);
  font-weight: bold;
  padding: 18px 35px;
  border: 1px solid var(--white);
  border-radius: 30px;
  color: var(--white);
  background-color: var(--primary);
  background-image: linear-gradient(var(--secondary), var(--secondary));
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0% 0%;
  margin: 30px 20px;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000033;
  transition: background-size 0.2s, color 0.2s;
  outline: none;
  &:hover {
    background-size: 100% 100%;
    color: var(--white);
    border: 1px solid var(--primary);
  }
`;

import styled from 'styled-components';

export const TextAreaInput = styled.textarea`
  width: 100%;
  max-width: 600px;
  margin: 10px;
  border: 1px solid #fff;
  background: transparent;
  height: 70px;
  font-weight: bold;
  font-family: var(--poppins);
  color: var(--white);
  cursor: text;
  -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  transition: all ease-in-out 0.2s;
  padding: 15px;
  font-size: 15px;
  @media (max-width: 700px) {
    width: 270px;
  }
  &::placeholder {
    font-size: 15px;
  }
  &:focus {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.35);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.35);
    input {
      border-color: #fff;
    }
    outline: unset;
  }
`;

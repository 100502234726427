import { ComponentType, ReactElement } from 'react';
import { IconBaseProps } from 'react-icons';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { Container, IconHolder, StyledPhoneInput } from './styles';
import 'react-phone-input-2/lib/bootstrap.css';

interface MaskedIconTextInputProps extends NumberFormatProps {
  icon: ComponentType<IconBaseProps>;
  iconColor?: string;
  textColor?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const defaultProps: Partial<MaskedIconTextInputProps> = {
  iconColor: '#fff',
  textColor: '#fff',
};

const MaskedIconTextInput = ({
  icon: Icon,
  iconColor,
  textColor,
  onChange,
  ...rest
}: MaskedIconTextInputProps): ReactElement => {
  const handleInputChange = (
    value: any,
    country: any,
    e: any,
    formattedValue: string,
  ) => {
    onChange({
      target: { value: formattedValue, name: 'phone' },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const customMasks = {
    br: '(..) .....-....',
  };
  return (
    <Container>
      <StyledPhoneInput
        country={'br'}
        onChange={handleInputChange}
        masks={customMasks}
      />
      {Icon && (
        <IconHolder>
          <Icon size={25} color={iconColor} />
        </IconHolder>
      )}
    </Container>
  );
};

MaskedIconTextInput.defaultProps = defaultProps;

export default MaskedIconTextInput;

import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 2.3rem;
  font-weight: bold;
  font-family: var(--poppins);
  margin-top: 80px;
  color: var(--white);
  text-align: center;
  span {
    color: var(--secondary);
  }
  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;

export const TitleService = styled.h1`
  font-size: 2.3rem;
  font-weight: bold;
  font-family: var(--poppins);
  margin-top: 5px;
  color: var(--white);
  text-align: center;
  span {
    color: var(--secondary);
  }
  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;

export const SubtitleNewCardMedias = styled.button`
  background: none;
  height: 80px;
  width: 180px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1.4rem;
  font-family: var(--poppins);
  margin: 10px;
  padding: 10px;
  color: var(--white);
  text-align: center;
  line-height: 1.6;
  span {
    font-size: 1.4rem;
    color: var(--secondary);
  }
  @media screen and (max-width: 500px) {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 1rem;
    text-align: center;
    margin: 10px;
    padding-top: 20px;
    height: 40px;
    width: 400px;
  }

  @media screen and (min-width: 500px) {
    font-size: 1.2rem;
  }
`;

export const Text = styled.h4`
  font-size: 1rem;
  font-family: var(--poppins);
  margin-top: 80px;
  color: var(--white);
  text-align: center;
  font-weight: normal;
  span {
    color: var(--secondary);
  }
`;

export const TextCircle = styled.h4`
  font-size: 1rem;
  font-family: var(--poppins);
  margin-top: 10px;
  color: var(--white);
  text-align: center;
  font-weight: normal;
  span {
    color: var(--secondary);
  }
  @media screen and (max-width: 500px) {
    font-size: 0.75rem;
    text-align: left;
    margin: none;
    max-width: 85%;
  }
`;

export const CardAnchor = styled.div`
  font-size: 1.2rem;
  font-family: var(--poppins);
  color: var(--white);
  position: absolute;
  bottom: 25px;
  font-weight: bold;
  width: calc(100% - 20px);
  text-align: center;
  span {
    color: var(--secondary);
  }
`;

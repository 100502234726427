import styled from 'styled-components';
import { GiClick } from 'react-icons/gi';

export const Container = styled.div`
  height: 400px;
  width: 350px;
  text-align: center;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  right: 100px;
  &:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    transform: scale(1.1);
    @media screen and (max-width: 500px) {
      transform: scale(1);
    }
  }
  @media screen and (max-width: 677px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* border: 2px solid #ccc; */
    max-height: 250px;
    max-width: 200px;
    right: 0px;
  }
`;

export const Circle = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 100px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  position: relative;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  margin: auto;
  @media screen and (max-width: 500px) {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`;

export const Image = styled.img`
  width: 300px;
  height: 450px;
  position: absolute;
  top: 0;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: auto;
  @media screen and (max-width: 677px) {
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    margin-top: 0px;
    max-height: 280px;
    max-width: 180px;
  }
`;

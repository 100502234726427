import React from 'react';
import Card from '../../components/Card';
import { Text, TitleService } from '../../styles/typography';
import { Container, Row } from '../styles';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

const Service: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Text style={{ marginTop: 0, marginBottom: 5 }}>1/6</Text>

      <Text style={{ marginBottom: 10, marginTop: 0 }}>
        {t('serv-inicial')}
      </Text>
      <Text style={{ marginBottom: 5, marginTop: 0 }}>
        {t('scroll-warning')}
      </Text>
      <TitleService style={{ marginBottom: 0 }}>
        {t('serv-what')} <span>{t('serv-you')}</span> {t('serv-need')}
      </TitleService>
      <Text style={{ marginBottom: 10, marginTop: 0 }}>
        {t('Clique para avançar de etapa')}
      </Text>

      <Row>
        <Card
          title={t('serv-aplicativo')}
          imgName={'app.png'}
          name="service"
          value="Gostaria de um app"
          path={'app'}
          description={t('serv-descript-aplicativo')}
        />
        <Card
          title={t('serv-software')}
          imgName={'software.png'}
          name="service"
          value="Gostaria de um software"
          path={'login'}
          description={t('serv-descript-software')}
        />
        <Card
          title={t('serv-IA')}
          imgName={'software2.png'}
          path={'login'}
          name="service"
          value="Gostaria de um website"
          description={t('serv-descript-IA')}
        />
        <Card
          title={t('serv-terceirização')}
          imgName={'terceirizacao.png'}
          name="service"
          value="Gostaria de terceirizar minha equipe de TI"
          path={'login'}
          description={t('serv-descript-terceirizacao')}
        />
      </Row>
    </Container>
  );
};

export default Service;

import { Image, Container } from './styles';

interface ICard {
  imgName: string;
  style?: React.CSSProperties;
}

const CardFinish = ({ imgName }: ICard) => {
  return (
    <Container>
      <Image src={`/images/${imgName}`} />
    </Container>
  );
};

export default CardFinish;

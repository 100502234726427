import styled from 'styled-components';
import { GiClick } from 'react-icons/gi';

export const Container = styled.div`
  height: 300px;
  width: 300px;
  text-align: center;
  position: relative;
  cursor: pointer;
  margin: 16px 16px;
  border-radius: 10px;
  border: 2px solid #ccc;
  &:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    transform: scale(1.1);
    @media screen and (max-width: 500px) {
      transform: scale(1);
    }
  }
  &:active {
    transition: opacity 0.35s ease-in-out;
    background-color: #ccc;
    transform: scale(0.88);
  }
  @media screen and (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 2px solid #ccc;
    margin: 8px 0;
    width: 80vw;
    row-gap: 2px;
    height: 100px;
  }
  @media screen and (min-width: 968px) and (max-width: 1363px) {
    height: 310px;
    width: 260px;
  }
`;

export const Circle = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 100px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  position: relative;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  margin: auto;
  @media screen and (max-width: 500px) {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`;

export const Image = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: auto;
  @media screen and (max-width: 500px) {
    max-width: 40px;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 10px;
    right: 0;
    padding-bottom: 50px;
  }
`;

export const ClickIcon = styled(GiClick)`
  width: 40px;
  height: 40px;
  fill: var(--white);
`;

export const CardInfo = styled.div`
  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    align-items: flex-start;
    padding-bottom: 30px;
    height: 60px;
    width: 250px;
  }
`;

import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: min(300px, 100%);
  border-radius: 5px;
  margin: 15px;

  cursor: text;
  -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  transition: all ease-in-out 0.2s;
  &:focus-within {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.35);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.35);
    input {
      border-color: #fff;
    }
  }
`;

export const IconHolder = styled.div`
  position: absolute;
  left: 10px;
  svg {
    height: 20px;
    width: 20px;
  }
`;

export const Input = styled.input`
  width: 100%;
  display: flex;
  border: unset;
  background: unset;
  color: unset;
  padding: unset;
  outline: none;
  font-weight: bold;
  font-family: var(--poppins);

  padding: 15px 20px;
  border: 1px solid #fff;
  font-size: 15px;
  &::placeholder {
    font-size: 15px;
  }
`;

export const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    background-color: unset;
    color: #ffffff;
    width: 100%;
    display: flex;
    border: unset;
    background: unset;
    padding: unset;
    outline: none;
    font-weight: bold;
    font-family: var(--poppins);

    padding: 15px 20px;
    padding-left: 95px;
    border: 1px solid #fff;
    font-size: 15px;
    &::placeholder {
      font-size: 15px;
    }
    &:focus {
      background-color: unset;
      border-color: #fff;
    }
  }
  .flag-dropdown {
    width: 50px;
    height: 50px;
    position: absolute;
    padding-left: 35px;
  }

  .react-tel-input {
    border-radius: 0px !important;
  }
`;
